import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import logo from "../../assets/css/images/foliologo.png";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import picture from "../../images/digital-design-large.jpg";

export default () => (
  <Layout isHome={false} pageId="folio_single_item" logo={logo}>
    <SEO title="Digital Design" />
    <div id="content">
      <div id="about_project">
        <h2 className="folio-title">Digital Design</h2>
        <div className="contact-intro">
          We partner with clients whose own work enhances society. Whose
          projects continue to inspire and challenge our team with increasingly
          interesting and complex problems.
        </div>

        <h5>Aesthetically Pleasing Showcase</h5>
        <p>
          Whether you're looking for an aesthetically pleasing showcase of your
          best work or a helpful and intuitive website espousing the benefits
          you bring to the table, our design team is ready to create your
          beautifully interactive SEO-friendly website.
        </p>
        <h5>Superior experience</h5>
        <p>
          Your website has the opportunity to become a powerful sales tool.
          Engcode Digital Marketing Agency builds websites that not only offer
          the visitor with a superior online experience but interacts and
          communicates with them in a way that compels them to sign up. We build
          lead-driven websites that focus on time-tested strategies for
          attracting visitors and converting them into paying customers. It’s
          not enough to have a beautiful website; the most effective websites
          are the ones that are able to serve as a marketing channel on their
          own with the right conversion strategy.
        </p>
        <ul className="navig">
          <li className="prev">
            {/* <AniLink fade to="/solutions/custom-applications" rel="prev">
              Custom Applications
            </AniLink> */}
          </li>
          <li className="next">
            <AniLink fade to="/solutions/custom-applications" rel="next">
              Custom Applications
            </AniLink>
          </li>
        </ul>
      </div>

      <div id="preview">
        <div className="blogimg slide image_1 current">
          <img
            src={picture}
            className="attachment-large-folio-thumb size-large-folio-thumb"
            alt="Digital Design"
          />
        </div>
        <h5>Make a Good Impression</h5>
        <p>
          Your website is often your first and only chance to make a good
          impression when someone finds you online. It takes a lot of time and
          effort to make a good first impression. Engcode Digital Marketing
          Agency has been building beautiful websites which seal the deal for
          over nine years helping businesses large and small go the extra mile
          to close business.
        </p>
      </div>
    </div>
  </Layout>
)
